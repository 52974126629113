/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

exports.onClientEntry = () => {
  if (!sessionStorage.getItem("ak-forwarded")) {
    let val = "direct"
    if (document.referrer.length > 0) {
      val = document.referrer
    }
    sessionStorage.setItem("ak-forwarded", val)
  }
}
